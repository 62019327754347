/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from "react";
import cn from "classnames";
import Imgix from "react-imgix";
import Flicking, { ChangedEvent } from "@egjs/react-flicking";
import { Dots } from "@brainhubeu/react-carousel";
import styles from "./Onboarding.module.scss";

const CarouselObjects = [
  {
    image:
      "https://imgix.cosmicjs.com/8cb87fa0-3f5e-11ec-b7f4-f97ad7f97dc7-HowItWorks1.png",
    title: "Custom Auction Creation",
  },
  {
    image:
      "https://imgix.cosmicjs.com/8cb635b0-3f5e-11ec-b7f4-f97ad7f97dc7-HowItWorks2.png",
    title: "Auction Management",
  },
  {
    image:
      "https://imgix.cosmicjs.com/8cb59970-3f5e-11ec-99ca-fbde970234a8-HowItWorks3.png",
    title: "Automatic Postings to Instagram",
  },
  {
    image:
      "https://imgix.cosmicjs.com/8cb4d620-3f5e-11ec-99ca-fbde970234a8-HowItWorks4.png",
    title: "Automatic Auction Tracking",
  },
];

const HowItWorksOnboarding = () => {
  const [hasWindow, setHasWindow] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    if (typeof window !== "undefined") {
      setHasWindow(true);
    }
  }, []);

  const handleCarouselChange = (value) => {
    setCurrentSlide(value);
  };

  return (
    <div
      className={cn([
        styles.howItWorksOnboardingContainer,
        styles.defaultContainer,
      ])}
    >
      {hasWindow && (
        <div className={styles.carouselContainer}>
          <Flicking
            align="center"
            defaultIndex={currentSlide}
            onChanged={(e: ChangedEvent) => handleCarouselChange(e.index)}
          >
            {CarouselObjects.map((obj, index) => (
              <div
                className="card-panel flex flex-col justify-center items-center mx-auto w-full"
                key={`how-it-works-step-${index}`}
              >
                <h3>How it Works!</h3>
                <h2>{obj.title}</h2>
                <Imgix
                  src={`${obj.image}?auto=format`}
                  width={356}
                  height={610}
                  htmlAttributes={{
                    alt: `How it works Step ${index + 1}`,
                    src: `${obj.image}?auto=format`,
                  }}
                  attributeConfig={{
                    src: "data-src",
                    srcSet: "data-srcset",
                    sizes: "data-sizes",
                  }}
                />
              </div>
            ))}
          </Flicking>
          <Dots
            value={currentSlide}
            onChange={(value) => handleCarouselChange(value)}
            number={CarouselObjects.length}
          />
        </div>
      )}
    </div>
  );
};

export default HowItWorksOnboarding;
