import React, { useEffect } from "react";
import queryString from "query-string";
import { AuthProvider } from "../../../components/Auth/auth";
import SimpleSpring from "../../../components/Transitions/transitions";
import styles from "../../../styles/Signin.module.scss";
import SignUpHeader from "../../../components/PageComponents/Signup/internal/SignUpHeader";
import LoginLayout from "../../../components/LoginLayout";
import HowItWorksOnboarding from "../../../components/Onboarding/HowItWorksOnboarding";

const CongratsPage = ({ location }) => {
  useEffect(() => {
    if (typeof window !== "undefined") {
      const { success } = queryString.parse(location.search);
      if (success === "payment_account") {
        window.close();
      }
    }
  }, []);

  return (
    <SimpleSpring location={location}>
      <SignUpHeader title="How It Works" />
      <AuthProvider>
        <LoginLayout hideHeader title="Bidddy - Onboarding How it works">
          <div className={styles.loginContainer}>
            <HowItWorksOnboarding />
          </div>
        </LoginLayout>
      </AuthProvider>
    </SimpleSpring>
  );
};

export default CongratsPage;
